@import "../../../theme/index";

.modal-group {
  padding-bottom: 10px;

  &__title {
    margin: 42px 68px;
    font-size: 48px;
  }

  &__cards {
    display: flex;
    overflow-x: auto;
  }
}
