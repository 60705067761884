@import "../../index";

.term{
  color: $black;
  &__list{
    &__item{
      &__description{
        color: $pigeonPost;
      }
    }
  }
}
