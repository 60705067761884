@import "../../index";

.situation {
  border: solid 1px $alto;

  &--checked {
    background: $grey;
  }

  &--checked::before {
    background-color: $lineGreen;
  }

  &:hover:not(&--checked) {
    background: darken($white, 2%);
  }

  &:active:not(&--checked) {
    background: darken($white, 4%);
  }
}
