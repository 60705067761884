@import "components/theme/index";

.d-button {
  background-color: $white;
  border: solid 2px $paarl;
  min-width: 50px;
  font-weight: bold;
  min-height: 40px;

  &:hover {
    cursor: pointer;
    background-color: darken($white, 4%);
    color: $black;
  }

  &--checked {
    background-color: $paarl;
    color: $alabaster;
  }
}
