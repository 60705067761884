@import "../../index";

.gritx-btn {
  &:hover:not(:disabled) {
    color: $white;
  }

  &:disabled {
    background-color: $grey;
  }

  // Primary variant styles
  &.gritx-btn-primary {
    background-color: $red;

    &:active {
      background: darken($red, 6%);
    }

    &:disabled {
      background-color: $grey;
    }
  }

  // Secondary variant styles
  &.gritx-btn-secondary {
    background-color: $semiDarkGrey;
    color: $white;

    &:hover:not(:disabled) {
      color: $black;
    }

    &:active {
      background-color: darken($semiDarkGrey, 6%);
    }

    &:disabled {
      background-color: $alto;
    }
  }

  // Light variant styles
  &.gritx-btn-light {
    background-color: transparent;
    color: $sirocco;

    &:hover:not(:disabled) {
      background-color: darken($white, 4%);
      color: $black;
    }

    &:active:not(:disabled) {
      background-color: darken($white, 8%);
    }

    &:disabled {
      background-color: transparent;
    }
  }

  // Outline variant styles
  &.gritx-btn-outline {
    background-color: $white;

    &:hover:not(:disabled) {
      background-color: darken($white, 4%);
      color: $black;
    }

    &:active:not(:disabled) {
      background-color: darken($white, 8%);
    }

    &:disabled {
      background-color: $grey;
    }
  }
}