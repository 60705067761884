@import "components/theme/index";

.text-box {
  &__required {
    color: $darkRed;
  }

  &__field {
    border: 1px solid $black;

    &--error{
      border-color: $darkRed;
    }
  }

  &__error {
    color: $darkRed;
  }
}