@import "components/theme/index";

.d-button {
  padding: 10px;
  margin: 2px 10px 2px 2px;
  &:hover{
    cursor: pointer;
  }
  &--checked{
  }
}
