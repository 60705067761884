@import "components/theme/index";

.input {
  display: flex;
  flex-direction: column;
  @include textSize;
  min-height: 6.5em;

  &__label {
    display: flex;
    flex-direction: column;

    &-text {
      margin-bottom: 10px;
    }
  }

  &__required {
  }

  &__field {
    padding: 10px;
    line-height: 1.5;

    &--error {
    }
  }

  &__error {
    display: block;
    font-size: 16px;
  }
}