@import "../../index";

.email-zone {
  border: 1px solid $alto;
  background-color: $white;
  color: $darkGrey;

  &__title {
    background-color: $grey;
    border-bottom: 1px solid $alto;
  }

  &__form {
    border-bottom: 1px solid $alto;
    background-color: $white;
  }

  &__add-btn {
    &:disabled {
      fill: $alto;
    }
  }

  &__footer {
    border-top: 1px solid $alto;
  }

  &__check-input {
    &:disabled + .email-zone__check__box {
      background-color: $grey;
    }
  }

  &__check-box {
    border: solid 1px $alto;
  }

  &__to-self-info {
    fill: $alto;
    &:hover{
      fill: $darkGrey;
    }
  }

  &__remove-all-btn.gritx-btn {
    color: $darkGrey;
    &:disabled {
      background-color: $grey;
    }
  }

  &__error {
    color: $red;
  }
}
