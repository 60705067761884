@import "../../index";

.contact-line {
  &__border {
    background-color: $lightGreen;
  }

  &__info {
    &__numbers {
      color: $blackApprox;

      &__number {
        color: $darkRed;
      }
    }
  }

  &__title {
    background-color: $blackApprox;

    &__text {
      color: $white;
    }
  }
}
