@import "../../../theme/index";

.lite-card {
  min-width: 274px;
  height: 330px;
  width: 334px;
  margin: 16px;
  display: flex;
  flex-direction: column;
  padding: 0;
  background-color: $white;
  border: solid 2px $alto;
  cursor: pointer;

  &__header {
    min-height: 75px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $grey;
  }

  &__title {
    padding: 0;
    margin: 0 20px;
    font-size: 22px;
  }

  &__body {
    padding: 7px;
  }

  &__image-wrapper {
    height: 161px;
    margin: 0 auto;
  }

  &__image {
    max-height: 100%;
    pointer-events: none;
  }

  &__description {
    font-size: 18px;
    text-align: left;
    padding: 10px 18px;
  }
}
