@import "../../index";

.chat {
  &__title {
    color: $darkGrey;
  }

  &__icon {
    border: solid 2px $alto;
  }
}
