@import "../../index";

.article {
  &__title {
    background-color: $grey;
  }

  &__props {
    border-bottom: 1px solid $grey;
  }

  &__actions {
    .gritx-btn.gritx-btn-primary {
      background-color: $white;
      border: 1px solid $paarl;

      &:active {
        background-color: $white;
      }

      &:hover {
        color: $paarl;
      }
    }
  }

  &__icon {
    &-img {
      fill: $red;
    }
  }

  &__button-icon {
    &:hover {
      background-color: darken($white, 4%);
    }
  }
}
