@import "../../index";

.filter-bar {
  background-color: $grey;

  &__search {
    border: solid 1px $alto;
    background-color: $white;
  }
}
