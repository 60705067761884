@import "../../components/theme/index";

.content {
  margin-bottom: 40px;

  &__wrapper {
    display: flex;
    flex-direction: column-reverse;
    padding: 0 10px;
    min-height: 150px;
    @include respond-to(desktop) {
      padding: 0 25px;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__articles {
    @include respond-to(desktop) {
      width: 48%;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;

    .article-area {
      width: 290px;
      margin-right: 1%;
      @include respond-to(tablets) {
        min-width: 200px;
        width: 48%;
      }

      &__card-place {
        width: 100%;
      }
    }
  }

  &__view {
    @include respond-to(desktop) {
      width: 48%;
    }
  }

  &__data {
    position: relative;
    min-height: 200px;
  }

  &__footer {
    display: flex;
    justify-content: center;

    .gritx-btn {
      width: 40%;
    }
  }

  &__back-to-top {
    position: fixed;
    right: 0;
    top: 50%;
    z-index: 1;

    .gritx-btn {
    }
  }

  &__library-button {
    display: block;
    margin: 50px auto;
    @include respond-to(tablets) {
      width: 40%;
    }
  }

  &--no-article {
    .content {
      &__articles {
        width: auto;
      }

      &__list {
        .article-area {
          @include respond-to(desktop) {
            width: 32%;
          }
          @include respond-to(largedesktop) {
            width: 24%;
          }
        }
      }
    }
  }

  &--no-items {
    .content {
      &__view {
        width: 100%;
      }
    }
  }
}

.breadcrumbs {
  padding: 0 10px;
  font-size: 24px;
  @include respond-to(desktop) {
    font-size: 2.5vw;
    padding: 0 25px;
  }
  @include respond-to(fullHD) {
    font-size: 48px;
  }

  &__previous {
    text-transform: uppercase;
    font-weight: bold;

    &:visited {
    }

    &:hover {
    }
  }
}