@import "../../index";

.help-info {
  background-color: $grey;
}

.help-info-line{
  color: $blackApprox;
}

.feedback-button {
  &:hover {
    color: $blue;
  }
}
