@import "../../index";

.interview {
  &__page {
    background-color: $white;
  }

  @keyframes buttonFade {
    from {
      background-color: $grey;
    }
    to {
      background-color: $red;
    }
  }
}
