@import "../../index";

.connect-line-color {
  background-color: $grey;
}

.connect-line {
  padding: 50px 3% 40px;
  @include respond-to(desktop) {
    padding-top: 80px;
    padding-bottom: 70px;
  }
  @include respond-to(largedesktop) {
    padding-top: 100px;
    padding-bottom: 90px;
  }

  &__border {
    height: 50px;
    background-color: $gallery;
    border-top: 1px solid $paarl;
  }

  &__info {
    &__connect-button {
      -webkit-tap-highlight-color: transparent;

      &:hover {
        .icon-svg {
          fill: $paarl;
        }
      }
    }
  }
}
