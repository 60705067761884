@import "../../index";

.journal {

  &__question {
    &--preview p {
      color: $silver;
    }
  }

  &__right-button {
    &.gritx-btn.gritx-btn-primary {
      background-color: $white;
      border: 1px solid $paarl;

      &:hover {
        color: $paarl;
      }
    }
  }

  &__answer {
    background: repeating-linear-gradient(to bottom, transparent, transparent 1.15em, $alto 1.2em) local;

    &--preview {
      color: $darkGrey;
    }
  }
}
