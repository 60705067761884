@import "../../index";

.xpedition {
  &__title {
    text-transform: initial;
  }

  &__icon {
    border: solid 2px $alto;
  }

  &__breadcrumbs {
    &-link {
      &:visited {
        color: $darkGrey;
      }

      &:hover {
        color: $paarl;
      }
    }

    &-current {
      font-weight: normal;
    }
  }
}

.xpedition__modal {
  .modal {
    &__header {
      height: 100px;
      margin-left: auto;
    }
    &__close-btn {
      border: none;
      font-weight: bold;
      font-size: 40px;
      text-transform: capitalize;
      line-height: 100px;
      width: 100px;
      cursor: pointer;
      background-color: transparent;
    }
  }
}
