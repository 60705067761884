@import "../../index";
.additional-information-color {
  background-color: $thunder;
}

.additional-information {
  min-height: 50px;
  padding: 0 3% 20px 3%;

  @include respond-to(tablets) {
  }
  @include respond-to(desktop) {
  }
  &__copyright {
    color: $lightGreen;
  }

  &__privacy-terms {
    color: $lightGreen;
  }

  &__button {
    color: $lightGreen;

    &:hover {
      color: $paarl;
    }
  }
}