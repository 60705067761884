@import "../../index";

.team {
  &__header {
    background-color: $grey;

    &::before {
      background-color: $grey;
    }

    &::after {
      background-color: $grey;
    }
  }

  &__separator {
    background-color: $lightGreen;
    &::before {
      background-color: $lightGreen;
    }

    &::after {
      background-color: $lightGreen;
    }
  }

  &__name {
    color: $darkRed;
  }

  &__information {
    background-color: $grey;
  }

}
