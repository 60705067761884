@import "../theme/index";

.gritx-btn {
  min-height: 60px;
  font-size: 24px;
  line-height: 60px;
  width: 100%;
  text-align: center;

  border: none;
  transition: color 0.3s;
  cursor: pointer;

  &:hover:not(:disabled) {
    transition: color 0.3s;
  }

  &:disabled {
    cursor: default;
  }

  // Primary variant styles
  &.gritx-btn-primary {
    font-weight: bold;
  }


  // Outline variant styles
  &.gritx-btn-outline {
    border: solid 1px $alto;
    font-weight: bold;
    min-height: 40px;
    line-height: 38px;

  }
}
