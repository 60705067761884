@import "../../theme/index";

.favorite {
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:disabled {
    .favorite__image{
      fill: $alto;
    }
  }

  &__icon {
    width: 34px;
    height: 34px;
    @include respond-to(largedesktop) {
      width: 36px;
      height: 36px;
    }
  }

  &__big-icon {
    width: 82px;
    height: 82px;
  }

  &__image{
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    fill: $red;
  }
}
