@import "../../index";

.info {
  background: $grey;

  &__item {
    .input__label-text {
      color: $black;
    }

    .input__field {
      border: solid 1px $alto;
    }

    .input__field--error {
      border: 1px solid $darkRed;
    }
  }
}

.settings {
  &__title {
    color: $darkGrey;
  }

  &__line {
    background-color: $grey;

    &:before {
      background-color: $grey;
    }

    &:after {
      background-color: $grey;
    }
  }
}
