@import "../../index";

.library {
  &__title {
    color: $darkGrey;
    text-transform: initial;
  }

  &__border{
    height: 50px;
    background-color: $alabaster;
  }
}
