@import "../../index";
.article-area {
  &__active-card {
    background-color: $alabaster;
  }

  &__title {
    color: $black;
    background-color: $grey;
  }

  &__card-picture {
    background-color: $white;
    height: 80%;
  }

  &__image {
    width: 137px;
    height: auto;
    @include respond-to(largedesktop) {
      width: 170px;
    }
  }

  &__description {
    color: $darkGrey;
  }

  &__category {
    background-color: $grey;
    color: $doveGray;
  }

  &__check-img {
    fill: $paarl;
  }
}