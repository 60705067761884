@import "../../index";

.snackbar{
  &__item{
    background-color: $red;
  }
  &__button{
    border: 1px solid $darkRed;
  }
}
