@import "components/theme/index";

.d-radio {
  margin: 20px;
  &__label {
    display: flex;
    margin-bottom: 20px;
    align-items: center;
    cursor: pointer;
    font-size: 20px;
  }

  &__field {
    margin-right: 10px;
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
}
