@import "../theme/index";

.bot {
  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include respond-to(tablets) {
      padding: 0 41px 100px;
    }

    @include respond-to(largedesktop) {
      flex-wrap: nowrap;
    }
  }

  &__window,
  &__content {
    margin: 5px 15px 20px;
    width: 100%;
    position: relative;

    .article {
      margin-top: 0;

      &__heading {
        padding: 0 40px;
        font-size: 35px;
      }
    }
  }

}

.bot-chat {
  &__avatar {
    width: 40px;
    height: 38px;
  }

  &__help-btn {
    width: 117px;
    margin-left: 74px;
  }

  &__toolbar {
    height: 60px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    &--xpedition{
      height: auto;
    }
  }

  &__help-btn {
    width: 117px;
  }

  &__title {
    font-weight: bold;
    font-size: 18px;
    @include respond-to(tablets) {
      font-size: 24px;
    }
  }

  &__wrapper {
    height: 440px;
    padding-bottom: 10px;
    margin-bottom: 18px;

    @include respond-to(tablets) {
      height: 500px;
    }
  }

  &__log {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
    padding-top: 10px;
  }

  &__message {
    position: relative;
    padding: 6px 0;
    opacity: 0;
    transition: all 0.15s ease-in-out;
    animation: fadeNewMessage 0.7s;
    animation-fill-mode: forwards;
  }

  &__message-url {
  }

  &__picture {
    min-width: 170px;
    min-height: 170px;
    max-width: 100%;
  }

  &__picture-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__picture-loader {
    position: relative;
    width: 170px;
    height: 170px;
    padding: 40px;
    background: transparent;
  }

  &__message-value {
    font-size: 20px;
    line-height: 26px;
    max-width: 90%;
    padding: 1rem;
    min-height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-line;

    @include respond-to(tablets) {
      max-width: 80%;
    }

    &:before {
      top: 36px;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-width: 12px;
      margin-top: -12px;
    }
  }

  &__group {
    display: flex;
    flex-direction: column;
  }

  &__message-buttons {
    display: flex;
    flex-wrap: wrap;
    margin-top: 8px;

    &:empty {
      margin-top: 0;
    }
  }

  &__chat-button.gritx-btn {
    width: auto;
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 2px 10px 2px 2px;
    text-align: left;
    flex-grow: 1;

    &.gritx-btn-outline {
      border: solid 2px $paarl;
    }

    @include respond-to(tablets) {
      font-size: 18px;
    }
  }

  &__group-button.gritx-btn {
    margin: 2px 0;
    width: 50%;
    min-height: 40px;
    line-height: 38px;
    flex-grow: inherit;
  }

  &__message--right {
    margin-right: 18px;
  }

  &__message--left {
    margin-left: 18px;
    font-weight: 600;
  }

  &__message--left &__message-value {
    float: left;
    text-align: left;

    &:before {
      right: 100%;
    }
  }

  .message-loading &__message-value {
    padding: 4px 1rem;

    &::before {
      margin-top: -22px;
    }
  }

  &__message--right &__message-value {
    float: right;
    text-align: right;

    &:before {
      left: 100%;
    }
  }

  &__start-message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 36px;
    font-weight: bold;
    height: 100%;
  }

  &__form {
    display: flex;
    align-items: flex-end;
  }

  &__suggester {
    border: 1px solid $darkGrey;
    position: absolute;
    z-index: 10;
    max-height: 200px;
    width: 100%;
    overflow: auto;
    margin: 0;
    padding: 0;
    list-style: none;
    background-color: $white;

    &-item {
      padding: 0;
      margin: 10px 0;
    }

    &-button {
      padding: 20px;
      width: 100%;
      background-color: transparent;
      border: none;
      text-align: left;
      font-size: 16px;

      &:hover {
        background-color: $alabaster;
      }
    }
  }

  &__input {
    flex: 1 1 auto;
    padding: 34px 20px 8px;
    font-size: 24px;
    min-height: 78px;
    width: 100%;
    resize: vertical;

    &::placeholder {
      font-style: italic;
      padding-bottom: 6px;
    }
  }

  &__send-btn {
    height: 78px;
    width: 108px;
  }
}

@keyframes fadeNewMessage {
  0% {
    opacity: 0;
    transform: translateY(1rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.message-card {
  display: flex;
  justify-content: center;

  &__wrapper {
    width: 100%;
    min-width: 250px;

    @include respond-to(tablets) {
      width: 416px;
    }
  }

  &__title {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    min-height: 75px;
    font-size: 19px;

    @include respond-to(tablets) {
      font-size: 24px;
    }
  }

  &__content {
    display: flex;
    max-height: 130px;
    overflow: hidden;

    @include respond-to(tablets) {
      max-height: 207px;
    }
  }

  &__picture {
    min-width: 50%;
    width: 50%;
    height: 100%;
    max-height: 100%;
    margin-top: auto;
  }

  &__picture-loader {
    position: relative;
    background: transparent;
    min-width: 50%;
    max-height: 100%;
    height: 107px;
    padding: 22px;
    margin: auto 0;

    @include respond-to(tablets) {
      height: 207px;
      padding: 40px;
    }
  }

  &__description {
    font-weight: normal;
    text-align: left;
    white-space: pre-wrap;
    overflow: hidden;
    font-size: 14px;
    padding: 8px;
    max-height: 130px;
    min-height: 130px;
    line-height: 17px;

    @include respond-to(tablets) {
      font-size: 18px;
      padding: 18px 13px 18px 13px;
      max-height: 207px;
      min-height: 207px;
      line-height: 23px;
    }
  }
}

.hidden {
  animation-name: hideMessage;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: ease;

  .bot-chat__message-value {
    max-height: 100%;
  }
}

@keyframes hideMessage {
  0% {
    max-height: auto;
    opacity: 1;
  }
  30% {
    max-height: 100%;
    opacity: 0;
  }
  100% {
    max-height: 0;
  }
}

.modal-content {
  height: 100%;
  width: 100%;
  padding: 0 42px;
}
