@import "../../index";

.backpack {
  &__border{
    height: 60px;
    background-color: $alabaster;
  }
  &__title {
    color: $darkGrey;
    text-transform: initial;
  }
}
