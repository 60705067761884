@import "../../index";

.materials {
  &__title {
    color: $darkGrey;
    text-transform: initial;
  }

  &__image-wrapper {
    display: none;
  }
}