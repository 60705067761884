@import "../../index";

.error-page {
  display: flex;
  flex-wrap: wrap;
  font-size: 48px;
  padding: 20px 3% 20px 3%;

  .not-found__image {
    display: none;
  }
}
