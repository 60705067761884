@import "components/theme/index";

.input {
  &__required {
    color: $darkRed;
  }

  &__field {
    border: 1px solid $black;

    &--error {
      border: 1px solid $darkRed;
    }
  }

  &__error {
    color: $darkRed;
  }
}