@import "../theme/index";

.meditation {
  &__open-button {
    width: 60%;
    display: block;
    margin: 0 auto 20px;
    line-height: initial;
  }

  &__modal {
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;

    .modal {
      &__header {
        height: 100px;
        display: flex;
        justify-content: flex-end;
      }

      &__close-btn {
        width: 50px;
        font-size: 24px;
        cursor: pointer;
      }

      &__body {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
      }
    }
  }

  &__circle {
    position: relative;

    circle {
      transform: rotate(-90);
      fill: transparent;
      stroke-width: 5;
    }

    circle:nth-child(2n) {
      fill: transparent;
      stroke-width: 5;
    }

  }

  &__step {
    position: absolute;
    width: 300px;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__description {
    height: 200px;
    min-width: 200px;
  }

  &__text {
    font-size: 24px;
    text-transform: capitalize;
    display: none;
    margin-bottom: 10px;

    &--show {
      display: block;
    }
  }

  &__last-message {
    display: none;

    &--show {
      display: block;
    }
  }
}
