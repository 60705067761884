@import "../theme/index";

.overlay {
  font-size: 16px;
  @include respond-to(desktop) {
    font-size: 18px;
  }
  @include respond-to(fullHD) {
    font-size: 20px;
  }

  p, li {
    margin-bottom: 10px;
  }
}
