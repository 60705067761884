@import "../../index";

.content {
  &__footer {
    margin-top: 40px;

    .gritx-btn.gritx-btn-primary {
      background-color: $white;
      border: 1px solid $paarl;

      &:hover {
        color: $paarl;
      }
    }
  }

  &__back-to-top {
    background-color: $white;

    .gritx-btn {
      border: 1px solid $semiDarkGrey;
    }
  }
}

.breadcrumbs {
  color: $darkGrey;

  &__previous {
    &:visited {
      color: $darkGrey;
    }

    &:hover {
      color: $paarl
    }
  }
}
