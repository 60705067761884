@import "../../index";

.meditation {
  &__modal {
    .modal {
      &__close-btn {
        background-color: transparent;
        border: 1px solid $black;
      }
    }
  }

  &__circle {
    circle {
      stroke: $yellow;
    }

    circle:nth-child(2n) {
      stroke: $green;
    }

  }

  &__step {
    color: $grey;
  }

  &__text {
    color: $white;
  }
}
