@import "../../index";

.avatars {
  &__item {
    border: 1px solid $alto;
  }

  &__btn {
    border: 1px solid $alto;
    background: $lightGreen;
    &-icon {
      fill: $green;
    }
  }
}
