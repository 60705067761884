@import "../../index";

.header {
  background-color: $alabaster;

  &__language {
    color: $black;
    background: $alabaster;
    &-wrapper {
      top: 25px;
      @include respond-to(tablets) {
        top: 30px;
      }
    }
  }

  &__menu {
    background: $alabaster;
  }

  .default-signIn-btn {
    background: $gallery;
    color: $paarl;

    .signIn-btn-image {
      display: none;
    }

    &:hover {
      color: $thunder;
    }

    &:focus {
      color: $thunder;
    }
  }

  .default-auth-user {
    background: $gallery;
    font-weight: initial;
    align-items: flex-end;
    padding-bottom: 5px;
    border-left: 1px solid $gallery;

    &:hover {
      color: $paarl;
    }

    &:focus {
      color: $paarl;
    }
  }

  .nav {
    &__item {
      border-bottom: 2px solid $gallery;

      &-text {
        text-transform: initial;
      }
    }

    &__link {
      color: $black;
    }
  }

  .menu-auth {
    background: $lightGreen;

    &__item {
      border-bottom: 2px solid $gallery;
    }

    &__btn {
      color: $black;

      &:hover {
        color: $paarl;
      }
    }
  }

  .language {
    &__title {
      background: $alabaster;
      color: $darkGrey;

      &:hover {
        color: $paarl;
      }
    }
  }

  .menu {
    &__button {
      color: $paarl;

      &-image {
        display: none;
      }
    }

    &__content {
      &-link {
        color: $darkGrey;

        &:hover {
          color: $white;
        }
      }
    }

    &__store {
      &__button {
        background-color: $semiDarkGrey;

        &:hover {
          color: $white;
        }
      }
    }
  }

  .burger {
    &__item {
      color: $black;
    }
  }

  @include respond-to(tablets) {
    .menu-auth {
      background: $alabaster;
    }
  }
  @include respond-to(largedesktop) {
    .nav {
      &__item {
        border-bottom: none;

        &::before {
          background: $alabaster;
        }
      }

      &__link {
        color: $black;

        &:hover {
          border-bottom-color: $paarl;
        }

        &-active {
          border-bottom-color: $paarl;

          .nav__item-text {
            font-weight: bold;
          }
        }
      }
    }
  }
}