@import "../theme/index";

.snackbar{
  position: fixed;
  bottom: 10px;
  left: 10px;
  z-index: 1;
  &__item{
    padding: 10px 20px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
  }
  &__button{
    background-color: transparent;
    margin-left: 20px;
  }
}
