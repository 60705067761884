@import "components/theme/index";

.d-textarea {
  margin-bottom: 10px;

  &__field {
    width: 100%;
    min-height: 100px;
    max-height: 500px;
    font-size: 18px;
    margin-top: 30px;
    line-height: 1.2em;
    background: repeating-linear-gradient(to bottom, transparent, transparent 1.05em, $alto 1.2em) local;
    border: none;
    resize: vertical;
  }
}
