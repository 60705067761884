@import "../../index";

.materials-share {
  &__header {
    background-color: $grey;

    &::before {
      background-color: $grey;
    }

    &::after {
      background-color: $grey;
    }
  }

  &__button.gritx-btn {
    border: 1px solid $alto;
    color: $darkGrey;

    &.gritx-btn-primary {
      background-color: $white;
      border: 1px solid $paarl;

      &:hover {
        color: $paarl;
      }

      &:disabled {
        background-color: $grey;
        border: 1px solid $alto;
        color: $darkGrey;
      }
    }

    &-light {
      background-color: $white;
    }
  }
}

.subject-selector {
  &__description {
    background-color: $grey;
  }
}