@import "components/theme/index";

.file-uploader {

  &__error {
    color: $darkRed;
  }
  &__button{
    background-color: $semiDarkGrey;
    color: $darkGrey;
  }
}

.dropzone {
  border-color: $white;

  &--active {
    border-color: $blue;
  }

  &--accept {
    border-color: $green;
  }

  &--reject {
    border-color: $darkRed;
  }
}