@import "../theme/index";

.journal {
  &__buttons, &__page-controls {
    & .gritx-btn {
      margin-bottom: 20px;
      height: 62px;
    }

    @include respond-to(tablets) {
      display: flex;
      justify-content: center;
      align-items: center;
      & .gritx-btn {
        width: 48%;
        max-width: 245px;

        &:first-child {
          margin-right: 20px;
        }
      }
    }
  }

  &__page-controls {
    justify-content: space-between;
    margin: 0 20px 20px;
    @include respond-to(tablets) {
      margin: 0 30px 20px;
    }
    @include respond-to(largedesktop) {
      margin: 0 50px 20px;
    }

    &--first-page {
      justify-content: flex-end;
    }

    .hide-button {
      display: none;
    }
  }

  &__buttons {
    &--last-entry {
      .gritx-btn {
        &:first-child {
          margin-right: 0;
        }
      }
    }
  }

  &__page {
    margin: 0 20px 20px;
    @include respond-to(tablets) {
      margin: 0 30px 20px;
    }
    @include respond-to(largedesktop) {
      margin: 0 50px 20px;
    }

    &-number {
      margin-top: 20px;
      font-size: 18px;
      font-weight: bold;
      text-transform: capitalize;
      @include respond-to(tablets) {
        font-size: 24px;
      }
    }
  }

  &__question {
    font-size: 18px;
    font-style: italic;
    @include respond-to(tablets) {
      font-size: 24px;
    }

    &--preview p {
      font-size: 18px;
      font-style: italic;
      @include respond-to(tablets) {
        font-size: 24px;
      }
    }
  }

  &__answer {
    width: 100%;
    font-size: 18px;
    margin-top: 30px;
    line-height: 1.2em;
    border: none;
    resize: vertical;

    &::placeholder {
      font-style: italic;
    }

    &--preview {
      font-size: 18px;
      font-weight: bold;
      @include respond-to(tablets) {
        font-size: 24px;
      }
    }
  }
}
