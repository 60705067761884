@import "../../index";

.privacy{
  color: $black;

  &__list{
    &__item{
      &__description{
        color: $pigeonPost;
      }
    }
  }
}