@import "../../index";

.material {
  &.gritx-btn.gritx-btn-primary {
    background-color: $alabaster;
    border-left: 1px solid $alto;
    border-bottom: 1px solid $alto;

    &:active {
      background-color: $alabaster;
    }

    &:hover {
      color: $paarl;
    }
  }

  &--active {
    &:hover {
      color: $white;
    }

    @include respond-to(largedesktop) {
    }
  }
}
