@mixin respond-to($media) {
  @if $media == tablets {
    @media only screen and (min-width: 640px) {
      @content;
    }
  } @else if $media == desktop {
    @media only screen and (min-width: 980px) {
      @content;
    }
  } @else if $media == largedesktop {
    @media only screen and (min-width: 1366px) {
      @content;
    }
  } @else if $media == widedesktop {
    @media only screen and (min-width: 1580px) {
      @content;
    }
  } @else if $media == bigdesktop {
    @media only screen and (min-width: 1732px) {
      @content;
    }
  } @else if $media == fullHD {
    @media only screen and (min-width: 1920px) {
      @content;
    }
  }
}

@mixin textSize {
  font-size: 16px;
  @include respond-to(desktop) {
    font-size: 1.2vw;
  }
  @include respond-to(fullHD) {
    font-size: 20px;
  }
}