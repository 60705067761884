@import "../../index";

.bot-chat {
  &__send-btn.gritx-btn.gritx-btn-primary {
    background-color: $paarl;

    &:active {
      background: darken($paarl, 6%);
    }

    &:disabled {
      background: $grey;
    }
  }

  &__toolbar {
    background-color: $grey;

    &--xpedition {
      background-color: $alabaster;
      padding-right: 0;
    }

    &--align-left {
      justify-content: flex-start;
      font-size: 24px;
      font-weight: bold;
    }
  }

  &__bot-avatar {
    fill: $red;
    height: 50px;
    width: 50px;

    &--hide {
      display: none;
    }
  }

  &__chat-button {
    &--link {
      padding: 10px;
      border: 1px solid $darkGrey;
      color: $darkGrey;
      text-decoration: none;

      &:hover {
        color: $paarl;
      }
    }
  }

  &__message-url {
    color: $black;
  }

  &__chat-button.gritx-btn {
    min-width: 40px;
    text-align: center;
  }

  &__message--left &__message-value {
    background: $grey;
    color: $black;

    &:before {
      border-right-color: $grey;
    }
  }

  &__message--right &__message-value {
    background: $alabaster;
    color: $darkGrey;

    &:before {
      border-left-color: $alabaster;
    }
  }

  &__input {
    border: solid 1px $alto;

    &::placeholder {
      color: $black;
    }
  }

}

.message-card {
  &__wrapper {
    background: $white;
    border: 1px solid $alto;
  }

  &__title {
    color: $black;
    background-color: $grey;
  }

  &__description {
    border-left: 1px solid $alto;
  }
}


