@import "../../index";

.main-area {
  &__about {
    &-button {
      background-color: $gallery;
      color: $paarl;

      &:hover {
        color: $thunder;
      }
    }

    &-description {
      &-wrapper {
        margin-bottom: 10px;
      }
    }
  }

  &__apps {
    .apps {
      background-color: $alabaster;

      &-content {
        &-text {
          color: $darkGrey;
        }
      }

      &__link {
        color: $black;

        &:visited {
          color: $black;
        }
      }

      &__qr {
        &-code {
          &-image {
            background-color: $alabaster;

          }
        }
      }
    }
  }

  &__header {
    color: $darkGrey;
  }

  &__sections {
    @include respond-to(largedesktop) {
      justify-content: space-between;
      margin-right: 40px;
    }

    .section {
      &__image {
        margin: 0;

        &-wrapper {
          &--green {
            background-color: transparent;
          }
        }

      }

      &__about {
        color: $darkGrey;
      }

      &__description {
        line-height: 1.22em;
      }
    }
  }
}

