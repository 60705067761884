@import "../../theme/index";

.chat-loader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  padding: 0 5px;
  position: relative;
  width: 60px;

  &__bar {
    background: #d6d6d6;
    border-radius: 50%;
    height: 12px;
    position: relative;
    width: 12px;

    &:first-of-type {
      animation: chat-typing-indicator 1.5s infinite;
    }

    &:nth-of-type(2) {
      animation: chat-typing-indicator 1.5s infinite 0.2s;
    }

    &:nth-of-type(3) {
      animation: chat-typing-indicator 1.5s infinite 0.4s;
    }
  }
}

@keyframes chat-typing-indicator {
  0% { top: 0; }
  6.25% { top: -1px; }
  12.5% { top: 0; }
  18.75% { top: 1px; }
  25% { top: 0; }

  12.5% { background: $grey; }
}
