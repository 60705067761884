@import "../../index";

.xpedition-item {
  &__header {
    background-color: $grey;
  }

  &__button {
    padding-bottom: 0;
    justify-content: center;
    height: 100%;
    min-height: 60px;

    &-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &:hover {
      color: $silver;
    }
  }
}
