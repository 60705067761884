@import "../../components/theme/index";

.backpack {
  &__title {
    font-size: 48px;
    font-weight: bold;
    text-align: left;
    margin: 20px 10px 10px;
    text-transform: uppercase;

    @include respond-to(tablets) {
      margin: 0 0 44px 51px;
    }
  }

  &__icon {
    width: 36px;
    height: 33px;
    margin-right: 6px;
  }

  &__cards {
    position: relative;
    min-height: 300px;
  }
}
